<template>
  <div class="l-contents l-area">
    <div class="l-box">
      <h3 class="c-lead icon">廃棄物情報</h3>
    </div>
    <div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label>廃棄物種類</label>
        </div>
        <div class="c-inputWrap__items">
          {{ formValue.routeInfo.jwnetItem.name }}
        </div>
      </div>

      <div class="c-accordion">
        <input id="disposal-info-acc" class="c-accordion__cb" type="checkbox" />
        <div class="c-accordion__head">
          <label class="c-accordion__head__lbl" for="disposal-info-acc"></label>
          <div class="c-accordion__head__text">詳細</div>
        </div>
        <div class="c-accordion__body for-form">
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>ルート</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols onTop l-area">
                <div>
                  <div
                    v-for="route in narrowedRoutesList"
                    :key="route.routeId"
                  >
                    <div v-if="route.routeId === formValue.routeInfo.routeId">
                      {{ route.routeName }}
                    </div>
                  </div>
                  <div>
                    <dl class="c-dl">
                      <dt>排出事業場：</dt>
                      <dd>{{ routeCompanyName.generateStore | blankText }}</dd>
                    </dl>
                    <dl class="c-dl">
                      <dt>運搬業者：</dt>
                      <dd>{{ formValue.routeInfo.collectCompanys && formValue.routeInfo.collectCompanys.map(cc => cc.name).join(",") | blankText }}</dd>
                    </dl>
                    <dl class="c-dl">
                      <dt>処分場：</dt>
                      <dd>{{ formValue.routeInfo.disposalSite && formValue.routeInfo.disposalSite.name | blankText }}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>廃棄物名称</label>
            </div>
            <div class="c-inputWrap__items">
              {{
                formValue.wasteInfo.wasteName
                  ? formValue.wasteInfo.wasteName.name
                  : "" | blankText
              }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>詳細名称</label>
            </div>
            <div class="c-inputWrap__items">
              {{ formValue.wasteInfo.detailWasteName | blankText }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>廃棄物数量</label>
            </div>
            <div class="c-inputWrap__items">
              {{
                formValue.wasteInfo.wasteQuantity
                  ? formValue.wasteInfo.wasteQuantity
                  : "" | formatFloat
              }}
              {{
                formValue.wasteInfo.wasteUnitType
                  ? formValue.wasteInfo.wasteUnitType.name
                  : ""
              }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>廃棄物確定数量</label>
            </div>
            <div class="c-inputWrap__items">
              {{
                formValue.wasteInfo.fixedWasteQuantity
                  ? formValue.wasteInfo.fixedWasteQuantity
                  : "" | formatFloat
              }}
              {{
                formValue.wasteInfo.fixedWasteQuantityUnitType
                  ? formValue.wasteInfo.fixedWasteQuantityUnitType.name
                  : ""
              }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>数量確定者</label>
            </div>
            <div class="c-inputWrap__items">
              {{
                formValue.wasteInfo.quantityConfirmBusinessType
                  ? formValue.wasteInfo.quantityConfirmBusinessType.name
                  : "" | blankText
              }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>荷姿</label>
            </div>
            <div class="c-inputWrap__items">
              {{
                formValue.wasteInfo.shapeType
                  ? formValue.wasteInfo.shapeType.name
                  : "" | blankText
              }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>荷姿数量</label>
            </div>
            <div class="c-inputWrap__items">
              {{
                formValue.wasteInfo.shapeQuantity
                  ? formValue.wasteInfo.shapeQuantity
                  : "" | blankText
              }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>有害物質名</label>
            </div>
            <div class="c-inputWrap__items">
              <div
                v-for="(harmfulItem, itemIndex) in formValue.harmfulList"
                :key="`hmfl-item-${itemIndex}`"
                class="l-flex start"
              >
                {{ harmfulItem.name }}
              </div>
              <div v-if="formValue.harmfulList.length === 0">-</div>
            </div>
          </div>

          <div
            v-for="routeLabel in formValue.labelInfo.manifestLabels"
            :key="routeLabel.labelId"
            class="c-inputWrap"
          >
            <div class="c-inputWrap__label">
              <label>{{ routeLabel.labelHeader }}</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeLabel.labelValue }}
              {{ routeLabel.labelUnit }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>備考</label>
            </div>
            <div class="c-inputWrap__items">
              <div
                class="l-area"
                v-for="taInfo in formValue.remarksInfo"
                :key="'ta' + taInfo.id"
              >
                {{ taInfo.detail }}
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>WDS</label>
            </div>
            <div
              class="c-inputWrap__items"
              v-if="formValue.routeInfo.wdsFileName"
            >
              <a
                class="c-text text-link c-entrustText__text"
                @click="
                  downloadWDS(
                    formValue.routeInfo.routeId,
                    formValue.routeInfo.wdsFileName
                  )
                "
              >
                {{ formValue.routeInfo.wdsFileName }}
              </a>
            </div>
            <div class="c-inputWrap__items" v-else>-</div>
          </div>
        </div>
        <!-- / c-accordion__body -->
      </div>
      <!-- / c-accordion -->
    </div>
  </div>
</template>

<script>
import idToName from "../mixins/idToName";
import axios from "axios";
import { API_URL } from "@/const";
import { saveAs } from "file-saver";

export default {
  props: {
    formValue: {
      type: Object,
    },
    routeCompanyName: {
      type: Object,
    },
    wasteUnitOpt: {
      type: Array,
    },
    narrowedRoutesList: {
      type: Array,
    }
  },
  mixins: [idToName],
  methods: {
    setRouteLabelStr() {
      // ルート用
      const r = this.formValue.routeInfo;
      this.routeCompanyName.generateStore = r.generateStore.name;
      this.routeCompanyName.disposalCompany =
        r.collectCompanys.length != 0 ? r.collectCompanys[0].name : "";
      this.routeCompanyName.collectCompany = r.disposalSite
        ? r.disposalSite.name
        : "";
    },
    // WDSファイルダウンロード
    downloadWDS(wasteInfoId, wdsFileName) {
      axios
        .get(API_URL.MANIFEST.WDS_DOWNLOAD + wasteInfoId, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type,
          });
          saveAs(blob, wdsFileName);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    init() {
      this.setRouteLabelStr();
    }
  },
  mounted() {
    this.init();
  }
};
</script>
