<template>
  <div class="l-contents">
    <div class="l-box">
      <h3 class="c-lead icon">マニフェスト情報</h3>
    </div>
    <div>
      <div class="c-inputWrap">
        <div class="c-inputWrap__label">
          <label>マニフェスト番号</label>
        </div>
        <div class="c-inputWrap__items">
          <div>
            {{ formValue.manifestInfo.manifestNo }}
          </div>
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label>引き渡し日</label>
        </div>
        <div class="c-inputWrap__items">
          {{ formValue.manifestInfo.collectionDate | dateJpYMDFormat }}
          {{ formValue.manifestInfo.collectionTime }}
        </div>
      </div>
      <div class="c-accordion">
        <input id="manifest-info-acc" class="c-accordion__cb" type="checkbox" />
        <div class="c-accordion__head">
          <label class="c-accordion__head__lbl" for="manifest-info-acc"></label>
          <div class="c-accordion__head__text">詳細</div>
        </div>
        <div class="c-accordion__body for-form">
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>交付日</label>
            </div>
            <div class="c-inputWrap__items">
              {{ formValue.manifestInfo.issueDate | dateJpYMDFormat }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>排出事業者名称</label>
            </div>
            <div class="c-inputWrap__items">
              {{
                formValue.manifestInfo.generateCompanyInfo
                  ? formValue.manifestInfo.generateCompanyInfo.name
                  : ""
              }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>排出事業場名称</label>
            </div>
            <div class="c-inputWrap__items">
              {{
                formValue.manifestInfo.generateStoreInfo
                  ? formValue.manifestInfo.generateStoreInfo.name
                  : ""
              }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>担当者</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>登録担当者</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.manifestInfo.registMember
                      ? formValue.manifestInfo.registMember.name
                      : "" | blankText
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>引き渡し担当者</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.manifestInfo.generateMember
                      ? formValue.manifestInfo.generateMember.name
                      : "" | blankText
                  }}
                </div>
              </div>
            </div>
          </div>  
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>連絡番号</label>
            </div>
            <div class="c-inputWrap__items">
              <template v-for="contactInfo in formValue.contactNoInfo">
                <div class="l-area" :key="contactInfo.id">
                  {{ contactInfo.contactNo }}
                </div>
              </template>
              <div v-if="formValue.contactNoInfo.length === 0">-</div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>最終修正日</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols l-area">
                {{
                  formValue.manifestInfo.manifestUpdateDate | dateJpYMDFormat
                }}
              </div>
            </div>
          </div>
        </div>
        <!-- /.c-accordion__body -->
      </div>
      <!-- /.c-accordion -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    manifestId: {
      type: Number,
    },
    formValue: {
      type: Object,
    },
  },
};
</script>
