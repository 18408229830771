export default {
  data() {
    return {
      // routeInfo  from callApi.js
      routeCompanyName: {},
      routeTempVal: "",
      collectCompanyId: "",
      disposalCompanyId: "",
      routeOpt: [],

      // 一時的に使用用
      // 絞り込み後のルート（画面遷移時はAPI返り値全件を代入）
      narrowedRoutesList: [],
    };
  },

  computed: {},

  methods: {
    // ルートの選択肢生成
    getRouteOptions() {
      if (!this.narrowedRoutesList.length) return;
      this.narrowedRoutesList.map((routeInfo) => {
        routeInfo.collectCompanys.map((collectCompany) => {
          let optLabel = "";
          let optVal = "";
          optLabel = `${routeInfo.routeId} - ${routeInfo.generateStore.name} - ${collectCompany.name} - ${routeInfo.disposalCompany.name}`;
          optVal = `${routeInfo.routeId}-${routeInfo.generateStore.id}-${collectCompany.id}-${routeInfo.disposalCompany.id}`;
          this.routeOpt = [
            ...this.routeOpt,
            {
              value: optVal,
              label: optLabel,
            },
          ];
        });
      });
    },

    initSelectedRoute() {
      this.routeCompanyName.generateStore = "";
      this.routeCompanyName.disposalCompany = "";
      this.routeCompanyName.collectCompany = "";
      this.formValue.manifestInfo.routeId = null;
      this.formValue.generateStore = null;
      this.collectCompanyId = null;
      this.disposalCompanyId = null;
    },

    // 廃棄物種類のchange処理
    jwnetItemChangeHandler() {
      this.initSelectedRoute();
      const selectedjwnetItemId = this.formValue.wasteInfo.jwnetItemTypeId;
      const newRouteList = this.routesInfo.filter(
        (routeInfo) => routeInfo.jwnetItem.id == selectedjwnetItemId
      );
      this.narrowedRoutesList = newRouteList;
    },

    /**
     * ルートの選択後に各会社情報を一時データに代入
     */
    getRouteCompanyName() {
      this.routesInfo.map((routeInfo) => {
        if (routeInfo.routeId == this.formValue.routeInfo.routeId) {
          this.routeCompanyName.generateStore = routeInfo.generateStore.name;
          this.routeCompanyName.disposalCompany =
            routeInfo.disposalCompany.name;
          routeInfo.collectCompanys.map((collectCompany) => {
            if (collectCompany.id == this.collectCompanyId) {
              this.routeCompanyName.collectCompany = collectCompany.name;
            }
          });
        }
      });
    },

    // 送信時はheaderを削除
    setAddedLabelData() {
      const foremattedLabelData = this.routeLabels.map((routeLabel) => {
        return {
          id: routeLabel.labelId,
          header: routeLabel.labelHeader,
          labelValue: routeLabel.labelDefaultValue,
        };
      });
      this.formValue.labelInfo.labels = [...foremattedLabelData];
    },

    // ルート選択時
    onChangeRouteHandler() {
      const splittedVal = this.routeTempVal.split("-");
      this.formValue.routeInfo.routeId = splittedVal[0];
      this.formValue.routeInfo.generateStore.id = splittedVal[1];
      this.collectCompanyId = splittedVal[2];
      this.disposalCompanyId = splittedVal[3];
      this.getRouteCompanyName();
      this.getRouteLabelsApi().then(() => {
        this.setAddedLabelData();
      });
    },
  },
};
