import axios from "axios";
import { API_URL } from "@/const";

export default {
    data() {
        return {
            //Jwnet連携中フラグ
            isJwnetReferring: false
        };
      },
    methods: {
        // JWNET連携中確認API
        getIsJwnetReffering(manifestNo) {
            return axios
                .get(`${API_URL.MANIFEST.IS_JWNET_REFERRING}/${manifestNo}`)
                .then((res) => {
                    this.isJwnetReferring = res.data.isJwnetReferring;
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    },
};