export default {
  methods: {
    getUnitLabel(unitId) {
      let label = "";
      this.wasteUnitOpt.map((unit) => {
        if (unit.id === unitId) {
          label = unit.name;
        }
      });
      return label;
    },

    getConfirmMember(memberId) {
      let label = "";
      this.confirmMemberOpt.map((member) => {
        if (member.id === memberId) {
          label = member.name;
        }
      });
      return label;
    },

    getShapeLabel(shapeId) {
      let label = "";
      this.shapeOpt.map((shape) => {
        if (shape.id === shapeId) {
          label = shape.name;
        }
      });
      return label;
    },
  },
};
