export default {
  data() {
    return {
      form: {
        collectionReportDate: "",
        collectMember: null,
        reportMember: null,
        carNo: "",
        collectQuantity: null,
        collectWasteUnitTypeId: null,
        valuablesQuantity: null,
        valuablesUnitTypeId: null,
        remark: "",
        directInput: "",
      },
    };
  },
};
