import moment from "moment";
import {EXTERNAL_API_URL, PROHIBITED_CHARACTERS_REGEX, DOMAIN, API_URL} from "@/const";
import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";

export default {
  data() {
    return {
      validate: {
        collectionReportDate: { isValid: true, errMsg: "" },
        collectMemberInfo: { isValid: true, errMsg: "" },
        reportMemberInfo: { isValid: true, errMsg: "" },
        carNo: { isValid: true, errMsg: "" },
        collectWay: { isValid: true, errMsg: "" },
        collectQuantity: { isValid: true, errMsg: "" },
        collectWasteUnitType: { isValid: true, errMsg: "" },
        valuablesQuantity: { isValid: true, errMsg: "" },
        valuablesUnitType: { isValid: true, errMsg: "" },
        remark: { isValid: true, errMsg: "" },
      },
    };
  },
  methods: {
    validatesCheck() {
      const v = this.validate;
      const f = this.form;
      const wasteQuantityRegexp = new RegExp(/^(\d{1,5}|0)(\.\d{1,3})?$/);
      let isValidForm = true;

      const collectionReportDate = moment(f.collectionReportDate);
      const oldCollectionReportDate = this.formValue.collectReportList.filter(collectReport => collectReport.section === 1)[0].collectionReportDate;
      const today = moment(this.currentDate).format("YYYY-MM-DD");

      if (!v.collectionReportDate.isValid) {
        v.collectionReportDate.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (f.collectionReportDate && f.collectionReportDate < f.manifestInfo.collectionDate) {
        v.collectionReportDate.errMsg = "運搬終了日は引き渡し日以降の日付を指定してください。";
        isValidForm = false;
      }
      if (f.collectionReportDate && oldCollectionReportDate && f.collectionReportDate > oldCollectionReportDate) {
        v.collectionReportDate.errMsg = "運搬報告日より未来の日付を設定できません。";
        isValidForm = false;
      }
      if (f.collectionReportDate && collectionReportDate.diff(today, "days") > 0 ) {
        v.collectionReportDate.errMsg = "運搬終了日は現在日時以前の日付を指定してください。";
        isValidForm = false;
      }
      if (!v.collectMemberInfo.isValid) {
        v.collectMemberInfo.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if ((f.collectQuantity && (!f.collectWasteUnitType || !f.collectWasteUnitType.id)) || 
          (!f.collectQuantity && f.collectWasteUnitType && f.collectWasteUnitType.id)) {
        v.collectWasteUnitType.errMsg = "運搬量の数量、単位いずれかのみの設定はできません。未設定もしくは、両方の設定をしてください。";
        isValidForm = false;
      } else {
        if (this.isCollectQuantityRequired && !f.collectQuantity) {
          v.collectQuantity.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
      }
      if ((f.valuablesQuantity && (!f.valuablesUnitType || !f.valuablesUnitType.id)) || 
          (!f.valuablesQuantity && f.valuablesUnitType && f.valuablesUnitType.id)) {
        v.valuablesUnitType.errMsg = "有価収拾量の数量、単位いずれかのみの設定はできません。未設定もしくは、両方の設定をしてください。";
        isValidForm = false;
      }
      if (f && f.carNo) {
        if (encodeURIComponent(f.carNo).replace(/%../g, "x").length > 30) {
          v.carNo.errMsg = "30バイト以内で入力してください。";
          isValidForm = false;
        }
        if (PROHIBITED_CHARACTERS_REGEX.test(f.carNo)) {
          v.carNo.errMsg = "登録できない文字が含まれています。";
          isValidForm = false;
        }
      }
      if (f && f.collectQuantity) {
        if (!wasteQuantityRegexp.test(f.collectQuantity)) {
          v.collectQuantity.errMsg =
            "整数5桁、小数点1桁、小数部3桁で入力してください。";
          isValidForm = false;
        }
      }
      if (f && f.valuablesQuantity) {
        if (!wasteQuantityRegexp.test(f.valuablesQuantity)) {
          v.valuablesQuantity.errMsg =
            "整数5桁、小数点1桁、小数部3桁で入力してください。";
          isValidForm = false;
        }
      }
      if (f && f.remark) {
        if (encodeURIComponent(f.remark).replace(/%../g, "x").length > 256) {
          v.remark.errMsg = "256バイト以内で入力してください。";
          isValidForm = false;
        }
        if (PROHIBITED_CHARACTERS_REGEX.test(f.remark)) {
          v.remark.errMsg = "登録できない文字が含まれています。";
          isValidForm = false;
        }
      }
      if (f.collectMemberInfo && f.collectMemberInfo.name) {
        if (f.collectMemberInfo.name.length > 12) {
          v.collectMemberInfo.errMsg = "12文字以内で入力してください。";
          isValidForm = false
        }
      }

      if (f.reportMemberInfo && f.reportMemberInfo.name) {
        if (f.reportMemberInfo.name.length > 12) {
          v.reportMemberInfo.errMsg = "12文字以内で入力してください。";
          isValidForm = false
        }
      }

      return isValidForm;
    },

    validateForIssue() {
      const w = this.formValue.wasteInfo;
      const m = this.formValue.manifestInfo;
      const adr = this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress;
      const wasteQuantityRegexp = new RegExp(/^(\d{1,5}|0)(\.\d{1,3})?$/);
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);
      const halfAlphaDigitRegexp = /^([0-9]\d*|0)$/;
      let promise = null;

      if (adr && adr.zipCode.length && halfAlphaDigitRegexp.test(adr.zipCode) && adr.zipCode.length !== 7) {

        promise = new Promise((resolve) => {
          // 何かの処理
          resolve(false);
        });

      } else {
        if (adr && adr.address.length) {
          const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${adr.zipCode}`;
          promise = axios
              .get(url, {adapter: axiosJsonpAdapter})  // Promise型
              .then((res) => {
                let isValidForm = true;
                const results = res.data.results;
                if (results != null) {
                  if (adr.prefecturesId) {
                    var prefectureFlg = false;
                    results.forEach((address) => {
                      const selectedPrefectureTemp = this.prefectures.find(p => p.id === adr.prefecturesId).name
                      if (selectedPrefectureTemp === address.address1) {
                        prefectureFlg = true;
                      }
                    });
                    if (!prefectureFlg) {
                      isValidForm = false;
                    }
                  }
                  if (adr.address.length > 100) {
                    var addressFlg = false;
                    results.forEach((address) => {
                      if (adr.address === address.address2) {
                        addressFlg = true;
                      }
                    });
                    if (!addressFlg) {
                      isValidForm = false;
                    }
                  }
                }
                return isValidForm;
              })
              .catch((err) => {
                console.log(err);
              });
        } else {
          promise = new Promise((resolve) => {
            resolve(true);
          });
        }
      }
      // 終了報告後自動交付」かつ「予約済み」の場合、ルート期限切れの場合は運搬終了報告不可
      return promise.then((prevIsValidForm) => {
        let isValidForm = prevIsValidForm;
        if (m.manifestStatusInfo.id
          && m.manifestStatusInfo.id !== DOMAIN.MANIFEST_STATUS.ISSUED
          && m.collectionDate
          && !this.routeDetailInfo.isEntrustUpdate
          && (m.collectionDate < this.routeDetailInfo.activeStartDate
              || (this.routeDetailInfo.activeEndDate && m.collectionDate > this.routeDetailInfo.activeEndDate))
          ){
          isValidForm = false;
          }
        if (!m.collectionDate
          || !m.registMember
          || !m.generateMember
          || !this.formValue.routeInfo || !this.formValue.routeInfo.routeId
          || !w.wasteName
          || !w.wasteUnitType || !w.wasteUnitType.id
          || !w.quantityConfirmBusinessType || !w.quantityConfirmBusinessType.id
          || !w.shapeType || !w.shapeType.id) {
          isValidForm = false;
        }

        if (w.wasteQuantity) {
          if (!wasteQuantityRegexp.test(w.wasteQuantity) || w.wasteQuantity == 0.0) {
            isValidForm = false;
          }
        } else {
          isValidForm = false;
        }

        if (this.formValue.collectCost && !isFinite(this.formValue.collectCost)
          || this.formValue.disposalCost && !isFinite(this.formValue.disposalCost)
          || this.formValue.valuablesCost && !isFinite(this.formValue.valuablesCost)) {
          isValidForm =  false;
        }

        if (w.shapeQuantity) {
          if (String(w.shapeQuantity).length > 5
          || !Number.isInteger(Number(w.shapeQuantity))
          || Number(w.shapeQuantity) <= 0) {
            isValidForm = false;
          }
        }

        this.formValue.remarksInfo.forEach((r) => {
          if (r.detail !== "") {
            if (this.countByteByString(this.toFullWidthString(r.detail)) > 50) {
              isValidForm = false;
            }
          }
        });

        this.formValue.contactNoInfo.forEach((ct) => {
          if (ct.contactNo !== "") {
            if (!halfAlphaRegexp.test(ct.contactNo)
            || encodeURIComponent(ct.contactNo).replace(/%../g, "x").length > 20) {
              isValidForm = false;
            }
          }
        });

        // 画面側で、任意事業場の項目に１つでも入力値があると、「事業場名」、「郵便番号」、「都道府県」、「市区町村」は、入力しないとエラーとする
        if ((this.formValue.anyGenerateStoreInfo.anyGenerateStoreName
            || adr || (adr && adr.buildingName))
            &&
            ! (adr.zipCode
            && adr.prefecturesId
            && adr.address
            && this.formValue.anyGenerateStoreInfo.anyGenerateStoreName)
        ) {
          isValidForm = false;
        }
        return isValidForm;
      });
    },

    // 交付時に引き渡し日が未来日でエラーとする
    validateCollectionDateForIssue() {
      const today = moment();
      const collectionDate = moment(this.formValue.manifestInfo.collectionDate);
      return collectionDate <= today;
    },

    runValidate() {
      return this.validatesCheck();
    },

    // ルート情報API API_URL.ROUTE.DETAIL/{routeId}
    getRouteInfoApi(routeId) {
      return axios
          .get(API_URL.ROUTE.DETAIL + routeId)
          .then((res) => {
            this.routeDetailInfo = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    checkValidateForIssue() {
      return this.validateCollectionDateForIssue() && this.validateForIssue();
    },
  },
};
