export default {
    methods: {

        changeCollectMemberInputForm() {
            this.form.collectMemberInfo.id = null;
            this.form.collectMemberInfo.name = null;
            this.validate.collectMemberInfo = { isValid: true, errMsg: "" }
        },

        changeReportMemberInputForm() {
            this.form.reportMemberInfo.id = null;
            this.form.reportMemberInfo.name = null;
            this.validate.reportMemberInfo = { isValid: true, errMsg: "" }
        },

        handleCollectMember() {
            if (!this.form.collectMemberInfo.id && !this.form.collectMemberInfo.name) {
                this.form.collectMemberInfo = null
            } else {
                if (this.isCollectMemberInputForm) {
                    this.form.collectMemberInfo.name = this.memberOpt.filter(m => m.id == this.form.collectMemberInfo.id)[0].name;
                } else {
                    this.form.collectMemberInfo.name = this.toFullWidthString(this.removeWhitespaceIfOnlyWhitespace(this.form.collectMemberInfo.name))
                }
            }
        },

        handleReportMember() {
            if (!this.form.reportMemberInfo.id && !this.form.reportMemberInfo.name) {
                this.form.reportMemberInfo = null
            } else {
                if (this.isReportMemberInputForm) {
                    this.form.reportMemberInfo = this.form.reportMemberInfo.id ?
                        {
                            id: this.form.reportMemberInfo.id,
                            name: this.memberOpt.filter(m => m.id == this.form.reportMemberInfo.id)[0].name
                        } : null;
                } else {
                    this.form.reportMemberInfo.name = this.toFullWidthString(this.removeWhitespaceIfOnlyWhitespace(this.form.reportMemberInfo.name))
                }
            }
        }
    }
}
